import { render, staticRenderFns } from "./RatingPresentation.vue?vue&type=template&id=1b9e1de1&"
import script from "./RatingPresentation.vue?vue&type=script&lang=js&"
export * from "./RatingPresentation.vue?vue&type=script&lang=js&"
import style0 from "./RatingPresentation.vue?vue&type=style&index=0&id=1b9e1de1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RatingStar: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/rating/RatingStar.vue').default})
