import { render, staticRenderFns } from "./FooterContact.vue?vue&type=template&id=7e408be6&scoped=true&"
import script from "./FooterContact.vue?vue&type=script&lang=js&"
export * from "./FooterContact.vue?vue&type=script&lang=js&"
import style0 from "./FooterContact.vue?vue&type=style&index=0&id=7e408be6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e408be6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterHeading: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterHeading.vue').default})
