

export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      default: null,
    },

    alt: {
      type: String,
      required: true,
    },

    errorSrc: {
      type: String,
      default: () => require('~/static/placeholders/products/product_placeholder.svg'),
    },

    width: {
      type: [Number, String],
      default: null,
    },

    height: {
      type: [Number, String],
      default: null,
    },

    sizes: {
      type: String,
      default: undefined,
    },

    loading: {
      type: String,
      default: 'lazy',
    },

    preload: {
      type: Boolean,
      default: false,
    },

    fetchPriority: {
      type: String,
      default: null,
    },

    fit: {
      type: String,
      default: 'cover',
    },

    format: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    isLoading: true,
    isError: false,
  }),

  head () {
    if (this.preload && this.src) {
      const format = this.src.split('.').pop()

      const { srcset, src } = this.$img.getSizes(this.src, {
        sizes: this.sizes || this.$img.options.screens,
        modifiers: {
          format,
          width: +this.width,
          height: +this.height,
        },
      })

      const link = {
        rel: 'preload',
        as: 'image',
        imagesrcset: srcset,
        src,
      }

      if (this.fetchPriority) {
        link.fetchPriority = this.fetchPriority
      }

      return {
        link: [link],
      }
    }

    return {}
  },

  computed: {
    imgAttrs () {
      return this.fetchPriority
        ? {
            fetchPriority: this.fetchPriority,
          }
        : {}
    },

    imgClass () {
      return [
        'base-image__img',
        ...this.getBemModifiers('base-image__img'),
      ].join(' ')
    },

    imgSrc () {
      let imgUrl = this.src

      try {
        imgUrl = this.src.startsWith('/') ? new URL(this.src, this.$config.appUrl).href : this.src
      } catch {}

      return this.isError ? this.errorSrc : imgUrl || this.errorSrc
    },
  },

  methods: {
    onLoad () {
      this.isLoading = false
    },

    onError () {
      this.isError = true
    },
  },
}
