
import { createNamespacedHelpers } from 'vuex'
import keepAlivePropsMixin from '~/mixins/keepAliveProps'

const {
  mapState: mapStateBreadcrumbs,
} = createNamespacedHelpers('breadcrumbs')

export default {
  name: 'ThePages',

  mixins: [
    keepAlivePropsMixin,
  ],

  computed: {
    ...mapStateBreadcrumbs({
      breadcrumbs: state => state.breadcrumbs,
    }),

    hasBreadcrumbs () {
      return !this.$route.path.includes('/search/q/') &&
        this.$route.path !== '/' &&
        this.$route.path !== '/thank-you' &&
        this.$route.path !== '/en'
    },
  },
}
